import React from 'react';
import styled from '@emotion/styled';

import StarIcon from '../assets/icon/star.svg';

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function Header({user}) {

  const {photoURL} = user;

  return (
    <HeaderContainer>
      <PhotoContainer>
        <a href="#">
          <Photo src={photoURL}
               alt="Home Image"/>
        </a>
      </PhotoContainer>
      <Title>
        <h1>Home</h1>
      </Title>
      <div>
        <img src={StarIcon} className="btn-icon" alt="Star Icon"/>
      </div>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 100%;
`

const Title = styled.div`
display: flex;
width: 100%;
height: 100%;
`

const PhotoContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
margin-left: 10px;
margin-top: 10px;
`

const Photo = styled.img`
border-radius: 10px;
`

export default Header;