import React from 'react';
import styled from '@emotion/styled'

/**
 *
 * @return {JSX.Element}
 * @constructor
 */
function Tweet({message}) {

  const {displayName, text, photoURL, createdAt} = message;

  return (
    <TweetContainer>
      <div>
        <div>
          <TweetImage>
            <TweetInnerImage src={photoURL || 'https://i.stack.imgur.com/wPh0S.jpg'} alt="Profile Picture"/>
          </TweetImage>
          <div className="tweet-text">
            <h6>{displayName || 'Unknown'} <span> - {createdAt.toDate().toLocaleDateString()}</span></h6>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </TweetContainer>
  );
}

const TweetContainer = styled.div`
display: flex;
width: 100%;
height: 100%;
`;

const TweetImage = styled.div`
display: flex;
width: fit-content;
height: fit-content;
`;

const TweetInnerImage = styled.img`
display: flex;
width: fit-content;
height: fit-content;
border-radius: 10px;
margin: 10px;
`;

export default Tweet;