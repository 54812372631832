import React from 'react';
import styled from '@emotion/styled'

import {Redirect} from 'react-router-dom';
import Footer from '../components/Footer';
import Tweets from '../components/Tweets';
import TweetIcon from '../assets/icon/tweet.svg';
import HomeIcon from '../assets/icon/home.svg';
import Header from '../components/Header';
import Loading from '../components/Loading';

class Home extends React.Component {

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    /**
     *
     * @type {firebase.User}
     */
    this.user = null;
    this.loading = true;
  }

  /**
   *
   */
  componentDidMount() {
    this.props.firebase.auth().onAuthStateChanged(user => {

      this.loading = false;
      if (user) {
        this.user = user;
      }

      this.forceUpdate();
    });
  }

  /**
   *
   * @returns {JSX.Element}
   */
  render() {

    if (!this.loading) {
      if (this.user) {
        return this.view();
      } else {
        return <Redirect to='/login'/>;
      }
    }

    return <Loading/>;
  }

  /**
   * View
   */
  view() {
    return (
      <Container>
        <Coucou>
          <h1>Hello</h1>
        </Coucou>
        <Comments>
            <TweetsContainer>
              <Tweets firebase={this.props.firebase}/>
            </TweetsContainer>
            <TweetButton>
              <div className="btn-tweet">
                <a href="/compose">
                  <img src={TweetIcon} width="45" alt="Button Tweet"/>
                </a>
              </div>
            </TweetButton>
            <TweetButton>
              <div className="btn-tweet2">
                <a href="/logout">
                  <img src={HomeIcon} width="45" alt="Button Tweet"/>
                </a>
              </div>
            </TweetButton>
        </Comments>
      </Container>
    );
  }
}

const Container = styled.div`
display: flex;
flex-direction: column;
width: 60%;
height: 100%;
`

const Coucou = styled.div`
display: flex;
width: 100%;
height: 20vh;
border: 1px solid grey;
`

const Comments = styled.div`
display: flex;
flex-direction: column;
width: 50%;
height: 60vh;
`

const HeaderContainer = styled.div`
display: flex;
width: 100%;
height: 125px;
border: 1px solid grey;
`

const TweetsContainer = styled.div`
display: flex;
flex: 1;
overflow-y: scroll;
border: 1px solid grey;
`

const TweetButton = styled.div`
display: flex;
width: 100%;
height: 50px;
background-color: purple;
`

export default Home;