import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Compose from './pages/Compose';

import './assets/scss/custom.scss';

/**
 * Firebase
 */
import firebase from 'firebase/app';

require('firebase/auth');
require('firebase/firestore');

firebase.initializeApp({
  apiKey: "AIzaSyB-DqJEdWTF7eR5QFqryjhVY1fbmSrZ4Sk",
  authDomain: "pedro-project-7a976.firebaseapp.com",
  projectId: "pedro-project-7a976",
  storageBucket: "pedro-project-7a976.appspot.com",
  messagingSenderId: "694911499384",
  appId: "1:694911499384:web:06484a960628db48b1418d",
  measurementId: "G-HQFSNZX07V"
});

/**
 * Render
 */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="container-iphone">
      <Router>
        <Switch>
          <Route path="/login">
            <Login firebase={firebase}/>
          </Route>
          <Route path="/logout">
            <Logout firebase={firebase}/>
          </Route>
          <Route path="/compose">
            <Compose firebase={firebase}/>
          </Route>
          <Route path="/">
            <Home firebase={firebase}/>
          </Route>
        </Switch>
      </Router>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
